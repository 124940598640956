<template>
  <AppLayout>
    <div style="background-color: #f2f2f2; padding-bottom: 100px" v-loading.fullscreen.lock="loading">
      <div class="container">
        <div style="height: 70px"></div>
        <div class="title">首页 > 优惠信息</div>
        <div style="background-color: #fff; padding-bottom: 350px">
          <div class="coupon-state">
            <ul class="tabBox">
              <li
                class="tabItem"
                v-for="(item, index) in coupinState"
                :key="item.id + index"
                :class="item.id === currentActive ? 'active' : ''"
                @click.self="changeTab(item)"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
          <div v-if="couponList.length" class="coupon-container">
            <div
              :class="
                currentActive == 1 || currentActive == 2
                  ? 'coupon-box'
                  : 'coupon-box-gray'
              "
              v-for="item in couponList"
            >
              <img
                v-if="currentActive == 2"
                src="./image/received.png"
                alt=""
                class="coupon-icon"
              />
              <img
                v-if="currentActive == 3"
                src="./image/use.png"
                alt=""
                class="coupon-icon"
              />
              <img
                v-if="currentActive == 4"
                src="./image/past.png"
                alt=""
                class="coupon-icon"
              />
              <div
                @click="toCouponDetails(item)"
                style="display: flex"
              >
                <div class="box-left">
                  <div>
                    <span>￥</span>
                    <span>{{ item.amount }}</span>
                  </div>
                  <div>满{{ item.amountOver }}元可用</div>
                </div>
                <div class="box-center">
                  <span>{{ item.couponName }}</span>
                  <span>{{ item.type == 0 ? "代金券" : "" }}</span>
                  <span
                    >{{ item.validityStart }} 至 {{ item.validityEnd }}</span
                  >
                </div>
              </div>
              <div class="box-right">
                <button
                  class="box-right-button"
                  @click="cliceCoupon(item.couponNumber)"
                >
                  {{ coupinState[currentActive - 1].buttonName }}
                </button>
              </div>
            </div>
          </div>
	        <div v-else class="default">
		        <img src="./image/default.png" alt="" style="width: 323px;height: 200px;">
		        <span style="font-family: Microsoft YaHei;font-weight: 400;font-size: 18px;color: #999999;">暂无优惠券信息</span>
	        </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
import AppLayout from "@/components/AppLayout.vue";
import { onMounted, ref } from "vue";
import { couponQueryList, couponReceive, couponReceiveList} from "@/api/coupon";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
const router = useRouter();
let loading = ref(false);

onMounted(() => {
  getCouponList();
});

let currentActive = ref(1); // 当前选择的优惠券状态
const coupinState = [
  {
    id: 1,
    title: "可领取",
    buttonName: "立即领取",
  },
  {
    id: 2,
    title: "待使用",
    buttonName: "立即使用",
  },
  {
    id: 3,
    title: "已使用",
    buttonName: "已使用",
  },
  {
    id: 4,
    title: "已失效",
    buttonName: "已过期",
  },
];
// 切换优惠券状态
const changeTab = (item) => {
  if (currentActive.value !== item.id) {
    currentActive.value = item.id;
    if (item.id == 1) {
      getCouponList();
    } else {
      getSpecifiedStatusCoupon(item.id - 2);
    }
  }
};

const couponList = ref([]); // 优惠券数据
// 查询可领取优惠券
const getCouponList = () => {
  couponQueryList().then((res) => {
    couponList.value = res.data;
  });
};
// 查询指定状态优惠券
const getSpecifiedStatusCoupon = (status) => {
  couponReceiveList(status).then((res) => {
    couponList.value = res.data;
  });
};
//查看详情
const toCouponDetails = (item) => {
	if(currentActive.value == 1){
		router.push({
			name:'couponDetail',
			query:{
				id:item.id,
				receiveId:1,
				status:currentActive.value
			}
		})
	}else{
		router.push({
			name:'couponDetail',
			query:{
				id:item.id,
				receiveId:item.receiveId,
				status:currentActive.value
			}
		})
		
	}
}
// 点击优惠券领取使用
const cliceCoupon = async (couponNumber) => {
  if (currentActive.value == 1) {
    try {
      const res = await couponReceive(couponNumber);
      if (res.code == 200) {
	      ElMessage({
		      message: "领取成功！",
		      type: "success",
	      });
        getCouponList();
      } else {
	      ElMessage({
		      message: res.message,
		      type: "error",
	      });
      }
    } catch (err) {
      console.log(err);
    }
  } else if(currentActive.value == 2) {
    router.push("/commercialProduct");
  }
};
</script>

<style scoped lang="less">
.container {
  width: 1080px;
  margin: 0 auto;
  .title {
    height: 84px;
    line-height: 84px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
  .coupon-state {
    padding: 52px 0 36px 63px;
    .tabBox {
      display: flex;
      margin-top: 10px;
      .tabItem {
        font-size: 15px;
        font-weight: 400;
        color: #666666;
        border-right: 1px solid #e1e1e1;
        padding: 0 15px;
        cursor: pointer;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: none;
        }
      }
      .active {
        //background: lighten(#ce1200, 50%);
        color: #ce1200;
      }
      :hover {
        //background: lighten(#ce1200, 50%);
        color: #ce1200;
      }
    }
  }
  .coupon-container {
    width: 900px;
    margin: 0 auto;
    .coupon-box {
      display: flex;
      height: 160px;
      margin-bottom: 20px;
      background-image: url("./image/background.png");
      background-size: cover;
      .coupon-icon {
        position: absolute;
        margin-left: 16px;
        margin-top: -8px;
        width: 51px;
        height: 52px;
      }
      .box-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        cursor: pointer;
        div:nth-child(1) {
          span:nth-child(1) {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #ce1200;
          }
          span:nth-child(2) {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 28px;
            color: #ce1200;
          }
        }
        div:nth-child(2) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #ce1200;
        }
      }
      .box-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 47px;
        width: 527px;
        cursor: pointer;
        span:nth-child(1) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 17px;
          color: #111111;
        }
        span:nth-child(2) {
          margin: 5px 0;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
        }
        span:nth-child(3) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
      }
      .box-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        .box-right-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 110px;
          height: 40px;
          background: #ce1200;
          border: none;
          border-radius: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
    .coupon-box-gray {
      display: flex;
      height: 160px;
      margin-bottom: 20px;
      background-image: url("./image/background_gray.png");
      background-size: cover;
      .coupon-icon {
        position: absolute;
        margin-left: 16px;
        margin-top: -8px;
        width: 51px;
        height: 52px;
      }
      .box-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        div:nth-child(1) {
          span:nth-child(1) {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #bbbbbb;
          }
          span:nth-child(2) {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 28px;
            color: #bbbbbb;
          }
        }
        div:nth-child(2) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #bbbbbb;
        }
      }
      .box-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 47px;
        width: 480px;
        span:nth-child(1) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 17px;
          color: #bbbbbb;
        }
        span:nth-child(2) {
          margin: 5px 0;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #bbbbbb;
        }
        span:nth-child(3) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #bbbbbb;
        }
      }
      .box-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        .box-right-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 110px;
          height: 40px;
          background: #eeeeee;
          border: none;
          border-radius: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 15px;
          color: #999999;
        }
      }
    }
  }
	.default{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
</style>
